body{
  font-family: 'Poppins', sans-serif!important;
}

header{
  height: 10vh;
}

/* Estilos para el tema claro */
.containerAudio{
  display: contents;
  margin-right: 5%;
}

.timeline {
  display: none; 
}

.containerAudio .scroll{
  max-height: 100px!important;
}

.containerAudio button.btn{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0 0 0);
  color: white;
  padding: 10px;
  margin: 0px 10px !important;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  border: none;
  animation: changing-border-only 2s linear infinite; 
}




textarea:focus, input:focus{
  outline: none;
}

/* Estilos para el tema claro */
.tema-claro {
  background-color: #ffffff;
  color: black;
  min-height: 100vh!important;
}

/* Estilos para el tema oscuro */
.tema-oscuro {
  background-color: black;
  color: white;
  min-height: 100vh!important;
}

.tema-oscuro h1, .tema-oscuro h2, .tema-oscuro h3, .tema-oscuro label,  .tema-oscuro .form-label{ 
  color: white;
}

.tema-oscuro .header {
  background-color: #000000;
}

.tema-oscuro .btn-primary {
  background-color: #fff0;
}

.tema-oscuro .btn.btn-secondary {
  border: 1px solid #ffffff;
  background-color: rgba(0, 0, 0, 0);
  color: #ffffff;
}

.tema-oscuro .btn.btn-secondary:hover {
  border: 1px solid #ffffff;
  background-color: #ffffff;
  color: #000000;
}

.tema-oscuro .burger-menu .line {
  background-color: #fff;
}

.tema-oscuro .footer {
  background-color: #000000;
  color: #fff;
}

.tema-oscuro #containerTextTranslator {
  background: #000000;
  border: 1px solid #ffffff;
}

.tema-oscuro #containerTextToVoice{
  background: #000000;
  border: 1px solid #ffffff;
}

.tema-oscuro .inputWithButtonContainer input{
  background-color: #000000;
  color: #ffffff;
}

.tema-oscuro .inputWithButtonContainer{
  background-color: #000000;
}

.tema-oscuro .header-left a {
  color: #ffffff;
}

.tema-oscuro .sidebar {
  background-color: #0f0f0f;
}

.tema-oscuro .header-right a {
  color: #000000;
}

.tema-oscuro #containerTranslator button {
  background-color: rgb(0 0 0);
  border: 1px solid #ffffff
}

.tema-oscuro #containerTranslator button:hover {
  background-color: rgb(0 0 0)!important;
}

.tema-oscuro .container.mt-5 {
  border: 1px solid #ffffff;
}

.tema-oscuro .footer a{
  color: #ffffff;
}

.tema-oscuro .contenedortrad {
  border: 1px solid #ffffff;
  background-color: #000000;
}

.tema-oscuro svg {
  vertical-align: middle;
  color: #ffffff;
}

.tema-oscuro .contenedortrad{
  background-color: #000000;
}

.tema-oscuro .accordion-collapse, .tema-oscuro .accordion-button{
  background-color: #000000;
  border: 1px solid #000000;
  color: #ffffff;
}

.tema-oscuro .accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: #000000;
  box-shadow: none;
}

.tema-oscuro textarea.autovoyage{
  color: #ffffff;
  background-color: #000000;
}

.tema-oscuro .card-body{
  color: #ffffff;
  background-color: #000000;
  border: 1px solid #ffffff;
  border-radius: 10px!important;
}

.tema-oscuro .card{
  border-radius: 10px!important;
}












/* --- Preguntas Frecuentes --- */
.accordion h2{
  margin: 5% 0 2% 0;
}

.accordion .accordion-item{
  margin: 10px 0 0 0;
  border-top: 1px solid #d1d1d1;
}

.accordion .accordion-item h2.accordion-header:first-child{
  margin: 0;
}

.accordion-button:not(.collapsed) {
  color: #000000;
  background-color: #ffffff;
  box-shadow: none;
}

.accordion-button:focus {
  z-index: 3;
  border-color: #000000;
  outline: none;
  box-shadow: none;
}

.accordion-body {
  font-weight: 200;
  font-size: 0.9em;
}



/*--- Desabilitamos botones que son !canPerformActions ---*/
.btn.disabled-gris{
  background-color: #616161!important;
  opacity: 0.5;
}


.speaker-image{
  width: 20px;
}

.main-content {
  padding: 0 0 5% 0;
}

.btn.btn-secondary {
  margin: 1% 1% 0 0;
  padding: 5px 10px;
  border: 1px solid #000;
  background-color: rgba(0, 0, 0, 0);
  color: #000000;
}

.btn.btn-secondary:hover{
  margin: 1% 1% 0 0;
  padding: 5px 10px;
  border: 1px solid #000;
  background-color: #000;
  color: #ffffff;
}

.btn.btn-primary{
  margin: 1% 1% 0 0;
  font-weight: 500;
  font-size: 1em;
}


input{
  border:none;
  height: 40px;
  border-radius: 5px;
}



textarea{
  width: 100%;
  border-radius: 5px;
  border: 1px solid #000000;
  margin: 2% 0;
  resize: none;
}

textarea.autovoyage{
  margin: 0;
  width: 100%;
  border-radius: 5px;
  border: none;
  background-color: #f3f3f3;
  resize: none;
}

textarea:focus {
  outline: none;
}

a{
  text-decoration: none;
  color: #fff;
}

#barraspeech{
  display: flex;
}

#barraspeech audio{
  width: 100%;
  margin: 2% 0;
}

body h1, body h2{
  color: #000000;
  font-weight: 600;
}

.title-container{
  text-align: center;
  padding: 2% 0;
}

p{
  font-weight: 400;
}

body .form-label {
  color: rgb(0, 0, 0);
}

body .btn-primary {
  background-color: #000000;
  border: none;
  margin-top: 10px;
}

body .btn-primary:hover{
  color: #ffffff!important;
}

.contenedorform {
  padding: 0 15%;
  margin-bottom: 10%;
}

.footer-left{
  display: flex;
  align-items: center;
}

.footer {
  background-color: #ffffff;
  color: #000;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  font-size: 12px;
  width: 100%;
}

.footer a{
  color: #000;
}

.footer-right #contact-button:hover{
  background-color: #000000!important;
}

.footer-right #contact-button:hover a{
  color: #ffffff!important;
}


/* Loader.css */
@keyframes pulserec {
  0%, 80%, 100% {
      transform: scale(0);
  }
  40% {
      transform: scale(1.0);
  }
}

/* Estilo para el fondo con desenfoque */
.loader-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1c083e;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px); /* Efecto de desenfoque */
}

.loader {
  display: inline-block;
  width: 64px;
  height: 64px;
  position: relative;
}

.loader div {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #6622d9;
  animation: pulserec 1.3s infinite;
  transform: translate(-50%, -50%);
}

.loader div:nth-child(1) {
  animation-delay: 0.32s;
}

.loader div:nth-child(2) {
  animation-delay: 0.16s;
}

.loader div:nth-child(3) {
  animation-delay: 0s;
}

img.flag {
  width: 15px;
}



/* Estilo recording  */

.recording-overlay img {
  width: 200px;
  animation: bounce 1s infinite, fadeInOut 1s infinite;
}

.recording-indicator {
  position: relative;
  width: 50px;
  height: 50px;
}

.circle {
  width: 100%;
  height: 100%;
  background-color: black;
  border-radius: 50%;
  animation: pulserec changing-border 1s infinite;
}

.wave {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background-color: black;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(1);
  opacity: 0.5;
  animation: waverec 1s infinite;
}





/*--- Graba Audio ---*/
.grabaaudiocontainer{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

#audiorecorder {
  width: 100%;
  margin: 2% 0;
}




/*--- Socials ---*/
.social-sidebar {
  display: flex;
  background-color: black;
  color: white;
  padding: 20px;
  justify-content: flex-start;
}

.social-sidebar a {
  color: white;
  font-size: 20px;
  margin: 10px 10px 10px 0;
  transition: color 0.3s;
}

.social-sidebar a:hover {
  color: #58fc0c;
}

/*---Header---*/
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-left img {
  height: 50px;
  width: auto;
}

.header-right {
  display: flex;
  align-items: center;
}

.header-right a{
  color: #ffffff;
}

.header-left a{
  color: #000000;
  font-size: 1.5em;
  font-family: 'Exo 2', sans-serif;
}

#arrow-amarillo {
  margin-right: 5px;
}

.btn-custom {
  background-color: #f2c94c;
  color: #333;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.btn-custom:hover {
  background-color: #f2fe00;
}

/* Burger menu */
.burger-menu {
  width: 30px;
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s;
  margin-left: 20px;
  z-index: 999999;
  right: 50px;
}

.burger-menu .line {
  width: 100%;
  height: 3px;
  background-color: #000;
  border-radius: 3px;
  transition: all 0.3s;

}

.burger-menu.active .line:nth-child(1) {
  transform: translateY(14px) rotate(45deg);
}

.burger-menu.active .line:nth-child(2) {
  opacity: 0;
}

.burger-menu.active .line:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

#contact-button:hover a{
  color: #000000!important;
}

#contact-button{
  margin: 0px;
}


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.80);
  z-index: 9998; 
}

.sidebar {
  position: fixed;
  left: -50vw;
  top: 0;
  bottom: 0;
  width: 50vw;
  background-color: #000000;
  transition: all 0.3s;
  padding: 5%;
  overflow-y: auto;
  z-index: 9999;
}

body .sidebar .welcome h2{
  color: #ffffff!important;
}

.sidebar button{
  border: 1px solid #ffffff;
}

.sidebar.open {
  left: 0;
}

.sidebar ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin-top: 15%;
  flex-direction: column;
}

.sidebar ul li {
  padding: 5px 15px;
  margin: 0 0 10px 0;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 10px;
}

.sidebar ul li:hover {
  padding: 5px 15px;
  /*background-color: #ffffff;*/
  border-radius: 10px;
  transition: all 0.3s;
}

.sidebar ul li:hover a {
  color: #000000;
  font-weight: 500;
}

.sidebar ul li a {
  font-size: 1.3em;
  color: #ffffff!important;
  text-decoration: none;
}

.subscriptionStatus p{ 
  padding:5px;
  border: 1px solid #fff;
  border-radius: 10px;
  animation: changing-border-only 2s linear infinite; 
  width: 100px;
  font-size: 10px!important;
  text-align: center;
}


@keyframes changing-border {
  0% { border-color: #FF6347; background-color: #FF6347; } /* Tomato */
  25% { border-color: #4682B4; background-color: #4682B4; } /* SteelBlue */
  50% { border-color: #32CD32; background-color: #32CD32; } /* LimeGreen */
  75% { border-color: #FFD700; background-color: #FFD700; } /* Gold */
  100% { border-color: #FF6347; background-color: #FF6347; } /* Back to Tomato */
}

@keyframes changing-svg-colors {
  0% { fill: #FF6347; stroke: #FF6347; } /* Tomato */
  25% { fill: #4682B4; stroke: #4682B4; } /* SteelBlue */
  50% { fill: #32CD32; stroke: #32CD32; } /* LimeGreen */
  75% { fill: #FFD700; stroke: #FFD700; } /* Gold */
  100% { fill: #FF6347; stroke: #FF6347; } /* Back to Tomato */
}

@keyframes changing-border-only {
  0% { border-color: #FF6347; } /* Tomato */
  25% { border-color: #4682B4; } /* SteelBlue */
  50% { border-color: #32CD32; } /* LimeGreen */
  75% { border-color: #FFD700; } /* Gold */
  100% { border-color: #FF6347; } /* Back to Tomato */
}

.sidebar ul li.nav-item.glowing{
  padding: 5px 15px;
  margin: 0 0 10px 0;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 10px;
  animation: changing-border-only 2s linear infinite; 
}

body button:hover{
  animation: changing-border 2s linear infinite!important; 
}

body .buttonhart:hover{
  transition: none!important; 
  animation: none!important; 
}

.sidebar ul li:hover {
  animation: changing-border 2s linear infinite!important; 
}




.sidebar p {
  font-size: 1em;
  color: white;
}

.navbar-collapse {
  display: flex;
  justify-content: center;
}

.navbar-nav {
  flex-direction: row;
}

.navbar-brand {
  margin:10px 20px;
}




p.pointsp{
  font-size: 8px;
  margin: 0;
  padding: 10px 0;
}






/*--- Contenedor Translator ---*/
#containerTextTranslator{
  height: 45vh;
  width: 80%;
  margin: 1% auto;
  overflow: scroll;
  padding: 2%;
  border: 1px solid #000000;
  border-radius: 20px;
  background: #f3f3f3;
}

#containerTranslator{
  display: flex;
  margin: 0 auto;
  width: 80%;
  padding: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#botones-language{
  margin: 5% 0;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
}

#botones-speakers{
  margin: 5% 0;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
}

/*---BOTONERA RECORD---*/
#botoneralanguagetoato {
  width: 100%;
  display: flex;
  padding: 10px 0;
  justify-content: flex-start;
}

.botonera-record img{
  width: 50px;
}

.botonera-record button{
  padding: 5px!important;
  border-radius: 50px!important;
}

.selectedButtonStyle.btn.btn-primary {
  background-color: #58fc0c;
  color: white;
}

.recording{
  transform: scale(0.9);
}

.recording.btn.btn-primary{
  animation: recording 1s infinite;
}

p.alertpoints{
  color: red;
  padding: 2px 10px;
  border: 1px solid red;
  border-radius: 5px;
}

.alertpoints a{
  color: red;
}

.botonera-record {
  position: relative;
  z-index: 10;
}

.recording-overlay {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.recording-overlay img {
  width: 100px;
  height: 100px;
}





/* Loader.css */
@keyframes recording {
  0%, 80%, 100% {
      transform: scale(0.9);
  }
  40% {
      transform: scale(1.0);
  }
}





/* Estilos Vision */

.drop-area {
  border: 2px dashed #aaa;
  border-radius: 8px;
  padding: 30px;
  cursor: pointer;
  margin-bottom: 20px;
  position: relative;
  transition: all 0.2s ease-in-out;
}

.drop-area.drag-over {
  border-color: #66afe9;
  background-color: #f7f7f7;
}

.image-preview {
  max-width: 100%;
  max-height: 200px;
  margin-bottom: 20px;
}

.analyze-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.analyze-button:hover {
  background-color: #0056b3;
}

.status-message {
  color: #6c757d;
  margin-bottom: 20px;
}

.result {
  margin-top: 20px;
}

.result-text {
  white-space: pre-wrap; 
  background-color: #f8f8f8;
  padding: 10px; 
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: 'Poppins', sans-serif!important;
  font-size: 16px; 
  color: #333; 
}










/*--- Select de language ---*/
#selector-language .dropdown {
  position: relative;
  display: inline-block;
}

#containerTranslator button{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0 0 0);
  color: white;
  padding: 10px;
  margin: 0px 10px!important;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  border: none;
  z-index: 999999;
  position: inherit;
}

#selector-language .dropbtn img{
  max-width: 20px;
  margin: 0 5px;
}

#selector-language .dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

#selector-language .dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
}

#selector-language .dropdown-content div:hover {
  background-color: #f1f1f1;
}

#selector-language .dropdown-content img {
  margin-right: 8px;
  width: 20px;
  height: auto;
}

#selector-language .dropdown:hover .dropdown-content {
  display: block;
}

#selector-language .dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.speakersdemosconainer{
  margin-top: 20px;
}

select#voiceSelect {
  margin: 5px 0;
  height: 40px;
  border-radius: 5px;
  width: 200px;
  margin-right: 10px;
}

.speakersdemosconainer button{
  width: 80px;
  border-radius: 5px;
  border: none;
  height: 40px;
  background: #ffffff;
}

.speakersdemosconainer p{
  margin-bottom: 0px;
}

#containerselectbuttonvoice{
  display: flex;
  align-items: center;
}




/*--- Card ---*/
.card{
  border-color: black;
}

.card-body span{
  width: 95px;
  display: flex;
  justify-content: center;
  padding: 2px 5px;
  border: 1px solid #000000;
  border-radius: 5px;
  margin: 0 auto 20px auto;
  font-size: 10px;
  animation: changing-border-only 2s linear infinite !important;
}





/*--- Login ---*/
.login-container{
  position: fixed;
  top: 0;
  display: flex;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: #000000;
  justify-content: center;
  align-items: center;
}

.loginpopup{
  width: 30vw;
  background-color: #ffffff;
  border-radius: 5px;
  text-align: center;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3% 1%;
}

.borderor{
  color: #000000;
}

.loginpopup img{
  width: 60%;
  padding: 10% 5% 2% 5%;
}

.loginpopup input{
  margin: 2% 0;
  width: 250px;
  padding: 0% 5%;
  padding: 0% 4%!important;
  border: 1px solid #000000;
}  


body img.iconeye{
  opacity: 0.8;
  position: absolute;
  top: 20px;
  right: 10px;
  cursor: pointer;
  transform: translateY(-25%);
  width: 20px;
  height: 20px;
  padding: 0;
} 

.loginpopup button{
  border: none;
  height: 40px;
  border-radius: 5px;
  margin: 5% 0 0% 0;
  width: 250px;
  color: #ffffff!important;
  background-color: #000000!important;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
} 

.loginpopup button:hover{
  background-color: #000000!important;
  color: #ffffff!important;
}

.loginform, .registerform{
  display: contents;
}

.loginform h3, .registerform h3, .loginform p{
  color: #000000!important;
}

.registerform form{
  display: contents;
}

.backButton{
  margin-bottom: 5px!important;
}

#loginbottomsection{
  display: inline-flex;
  font-size: 12px;
  flex-direction: column;
  align-items: center;
  margin: 0% 5% 5% 5%;;
}

button.backButton{
  background-color: #cacaca00!important;
  color: #000000!important;
  font-size: 16px;
  border: 2px dotted #000000;
  margin-top: 10%;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}  

p.littlep{
  color: #000000;
  font-size: 8px;
}

.error{
  padding: 5px;
  border-radius: 5px;
  color: #ff0000;
  width: 250px;
  font-size: 10px;
  border: 1px solid #ff0000;
  margin-top: 10px;
} 

.logeadocontainer {
  padding: 5px;
  background-color: #000000;
  font-size: 10px;
  text-align: center;
  animation: showHide 6.5s;
  animation-fill-mode: forwards;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 2;
  visibility: visible;
}

.logeadocontainer.logueadobar {
  visibility: hidden;
}

#googleicon{
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

#googleicon img{
  width: 20px;
  padding: 0 5px 0 0;
}

.headerUser{
  background-color: #000000;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.headerUser .btn.btn-primary{
  margin:5px;
}









.speechresult{
  display: flex;
}

.translatedtext {
  font-size: 1.2em;
  line-height: 1em;
  margin: 0 2% 0% 0;
  padding: 0 10px 10px 10px;
}


.transtext{
  font-size: .8em;
  line-height: 1;
  margin-bottom: 7px;
  padding: 10px;
}

.contenedortrad{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid #000000;
  background-color: #ffffff;
  padding: 5px 10px;
  margin-bottom: 15px;
  border-radius: 10px;
}

.contenedortexttrad{
  width: 90%;
}

.contenedoraudioplayhart{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
}

.modal-body {
  background-color: #080808;
  color: #ffffff;
  text-align: left;
  padding: 30px 50px;
}

.modal-body p{
  font-weight: 200;
}

.modal-footer {
  background-color: #0a0a0a!important;
  border-top: #0a0a0a!important;
}

.modal-header{
  background-color: #080808;
  border-bottom: #080808;
  color: #fff;
  justify-content: center;
  padding-bottom: 0px;
}

.btn-close{
  background-color: #58fc0c;
}



.suscriptioncontainer .card-title{
  display: flex;
  align-items: center;
}

.suscriptioncontainer .card-title img{
  margin-right: 5px;
  width: 30px;
}

.suscriptioncontainer .card-title h3{
  margin-bottom: 0px;
}

.anualmensualbutton{
  width: 300px;
  margin: 2% 0 2% 0!important;
  height: 40px;
}





/*--- Favorites ---*/
.buttonhart{
  border: none;
  background: none;
  
}






/*--- Manual Containers ---*/
.container.mt-5 {
  margin-top: 2rem!important;
  padding: 5%;
  border-radius: 20px;
  border: 1px solid #000;
}



/*--- Recording Loader ---*/
.recording-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  height: 30px;
  width: 24px;
}

.bar-animate {
  width: 4px;
  height: 5px;
  background-color: rgb(0, 0, 0);
  animation: stretch 0.6s infinite ease-in-out;
}

.bar-animate:nth-child(1) {
  animation-delay: 0s;
}

.bar-animate:nth-child(2) {
  animation-delay: 0.15s;
}

.bar-animate:nth-child(3) {
  animation-delay: 0.3s;
}

@keyframes stretch {
  0%, 100% {
    height: 5px;
    transform: translateY(13px);
  }
  50% {
    height: 21px;
    transform: translateY(5px);
  }
}







/* Toogle button */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch input:checked + .slider {
  background-color: rgb(84 84 84);
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px rgb(84 84 84);
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.switch .slider.round {
  border-radius: 34px;
}

.switch .slider.round:before {
  border-radius: 50%;
}







/* Dashboard */
.contenedortraddashboard{
  padding: 5%;
  border: 1px solid;
  margin: 5% 0;
  border-radius: 30px;
}

.audiocontainer{
  margin: 5% 0 0 0;
}





/* Audio Canvas */
.containerAudio .progress :host canvas {
  height: 60px !important;
}

.containerAudio :host .canvases {
  min-height: 60px!important;
}

.containerAudio :host .wrapper {
  height: 60px;
}





/* Input Traslator */
.inputWithButtonContainer {
  display: flex;
  align-items: center;
  background-color: #f3f3f3;
  border-radius: 5px;
  padding: 5px;
  margin-right: 1%;
  width: 99%;
}

.inputWithButtonContainer input {
  border: none;
  outline: none;
  padding: 10px;
  flex-grow: 1;
  font-size: 16px;
  border-radius: 4px;
  background-color: #f3f3f3;
}

.inputWithButtonContainer button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 18px!important;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

#botonerarecord{
  display: flex;
  margin-top: 10px;
  width: 100%;
  align-items: center;
}



/* Input Text To Voice */
#containerTextToVoice{
  padding: 1% 0 1% 1%;
  background-color: #f3f3f3;
  width: 100%;
  border: 1px solid #000000;
  border-radius: 20px;
}
    


#startbutton{
  width: 80%;
  margin: 0 auto 5% auto;
  border: 1px solid #fff;
  font-size: 1.3em;
  animation: changing-border-only 2s linear infinite;
}
    


.estasaqui .card{
  border: 1px solid #fff;
  animation: changing-border-only 2s linear infinite!important;
}

.modal-dialog {
  max-width: 60vw;
  margin: 10vh 20vw;
}


.botonesspeakers{
  display: flex;
  justify-content: center;
}

.speakersdemosconainer{
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.faqintro{
  padding: 5% 0 3% 0;
}

.faqfooter{
  padding: 5% 0 10% 0;
}

.faqfooter a{
  color: #000000;
}





/* --- Vision --- */
.Vision {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: auto;
  margin: 0vh;
}

.Vision h1 {
  color: #333;
  margin-bottom: 20px;
}

.Vision .camera-container,
.Vision .drop-area {
  border: 1px solid #000000;
  border-radius: 10px;
  max-width: 450px;
  height: 100%;
  text-align: center;
  margin-bottom: 20px;
  transition: border-color 0.3s;
  display: flex;
  align-items: center;
}

.Vision .camera-container.drag-over,
.Vision .drop-area.drag-over {
  border-color: #007bff;
}

.Vision .webcam {
  width: 100%;
  object-fit: cover; 
  border-radius: 10px;
}

.Vision .image-preview {
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: cover;
  border-radius: 10px;
  margin-top: 10px;
}

.Vision .status-message {
  margin-top: 10px;
  color: #555;
}

.Vision .analyze-button {
  background-color: #000000;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.drop-area p{
  padding: 10% 0;
  margin-bottom: 0px;
}

.Vision .analyze-button:hover {
  background-color: #0056b3;
}

.Vision .result {
  margin-top: 20px;
  width: 80%;
  max-width: 800px;
}

.Vision textarea {
  width: 100%;
  height: 100px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  resize: none;
}

.Vision button {
  margin: 10px 0;
}

.vision-button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.vision-button-container button{
  margin: 0 10px;
}

.camera-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1000;
}

.webcam-fullscreen {
  width: 100%;
  height: 90vh;
}

.camera-buttons{
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: flex-end;
  position: fixed;
  bottom: 50px !important;
  width: 100%;
  flex-direction: column;
}

.camera-buttons button{
  margin: 10px;
}

.capture-button, .close-button{
  margin: 10px;
  padding: 10px 20px;
  font-size: 18px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1001;
}

.zoom-button {
  border: none;
  border-radius: 30px;
  font-size: 12px;
  height: 30px;
  padding: 5px;
  width: 30px;
  background-color: #000;
  color: #ffffff;
}

.zoom-button.active {
  background-color: #ffffff;
  color: #000000;
}

.capture-button:hover, .close-button:hover {
  background-color: #000000;
}

.close-button {
  background-color: #000000;
}

.close-button:hover {
  background-color: #000000;
}








/*--- Key frames para circulos ---*/
@keyframes growGlobcircleGreen {
  0%{
      top:-5%;
  }
  50%{
      top:3%;
  }
  100%{
      top:-5%;
  }
}

@keyframes growGlobcircleRedBlue {
  0%{
      top:40%;
  }
  50%{
      top:45%;
  }
  100%{
      top:40%;
  }
}

@keyframes growGlobcirclePink {
  0%{
      top:70%;
  }
  50%{
      top:80%;
  }
  100%{
      top:70%;
  }
}

@keyframes growGlobcircleBlueSmall {
  0%{
      top:5%;
  }
  50%{
      top:10%;
  }
  100%{
      top:5%;
  }
}

@keyframes growGlobcirclePinkBlue {
  0%{
      top:70%;
  }
  50%{
      top:60%;
  }
  100%{
      top:70%;
  }
}

/* ----  Loader Record --- */
@keyframes pulserec {
  0% {
      transform: scale(1);
  }
  50% {
      transform: scale(0.9);
  }
  100% {
      transform: scale(1);
  }
}

@keyframes waverec {
  0% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 0.5;
  }
  100% {
      transform: translate(-50%, -50%) scale(1.5);
      opacity: 0;
  }
}





/* Media query para tablets */
@media (max-width: 768px) {
  .contenedorform {
    padding: 0 10%;
  }
  .contenedortrad {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px;
    flex-direction: column;
    position: absolute;
  }
  .contenedortrad .buttonhart{ 
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

/* Media query para móviles */
@media (max-width: 480px) {
  .modal-backdrop.show {
    z-index: 9999;
  }
  header{
    height: 10vh;
  }
  .modal-body {
    padding: 10px 20px;
  }
  .modal-title.h4 h3{
    font-size: 1.8em;
  }
  .Vision {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px 100px 20px;
    justify-content: center;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    position: fixed;
    z-index: 9998;
    width: 100%;
    background-color: #ffffff;
  }
  .webcam-fullscreen {
    height: 100vh;
    width: auto;
  }
  .botonesspeakers, .speakersdemosconainer{
    flex-direction: column;
  } 
  .modal-body p {
    font-size: 12px;
  }
  .container{
    padding: 10px 30px;
  }
  #botoneralanguagetoato {
    justify-content: center;
    align-items: center;
  }
  #botoneralanguagetoato .btn.btn-primary {
    font-size: 14px;
  }
  .modal-body .btn.btn-primary {
    width: 100%;
    text-align: left;
    padding: 10px;
    border-bottom: 1px solid #cacaca5c;
  }
  .modal-dialog {
    max-width: 90vw;
    margin:15vh 5vw;
  }
  .loginpopup {
    width: 90vw;
  }
  #containerTranslator{
    width: 100%; 
  }
  #containerTextTranslator {
    height: 50vh;
    width: 90%;
    margin: 5% auto;
  }
  #botonerarecord{
    width: 90%;
  }
  #botoneralanguagetoato{
    display: flex;
    width: 100%;
  }

  .sidebar {
    left: -100vw;
    width: 100vw;
    padding: 30px 20px;
  }
  .contenedorform {
    padding: 0 5%;
  }
  img#circleBlueSmall {
    right: -10%;
    width: 15%;
  }  
  img#circlePinkBlue{
    left: -5%;
    width: 7%;
  }
  img#circlePink {
    right: -10%;
    width: 20%;
  }  
  img#circleRedBlue {
    left: -15%;
    width: 20%;
  }  
  img#circleGreen {
    left: -3%;
    width: 15%;
  }   
  .cardsuscription{
    margin: 10% 0;
  }
  .modal-title {
    text-align: center;
  } 
  .fade.modal.show{
    z-index: 999999;
  }
}